<!-- FormComponent.vue -->
<template>
    <form @submit.prevent="submitForm">
      <h2 class="title">Edit Instance</h2>
      <div class="form-group">
          <label for="alias">Alias:</label>
          <input type="text" id="alias" v-model="formData.alias" required>
        </div>
      <div class="form-group">
        <label for="connectionIdTrade">Connection Id Trade:</label>
        <div class="custom-select">
            <select id="connectionIdTrade" v-model="formData.connectionIdTrade">
                
                <option value="">Select an option</option>
                <option v-for="option in connectionsList" :key="option.id" :value="option.id">{{ option.alias }}</option>
            </select>
            <div class="select-icon">
                <i class="fas fa-chevron-down"></i>
            </div>
        </div>
      </div>
      <div class="form-group">
        <label for="connectionIdQuoteSlow">Connection Id Quote Slow:</label>
        <div class="custom-select">
            <select id="connectionIdQuoteSlow" v-model="formData.connectionIdQuoteSlow">
                
                <option value="">Select an option</option>
                <option v-for="option in connectionsList" :key="option.id" :value="option.id">{{ option.alias }}</option>
            </select>
            <div class="select-icon">
                <i class="fas fa-chevron-down"></i>
            </div>
        </div>
      </div>
      <div class="form-group">
        <label for="connectionIdQuoteFast">Connection Id Quote Fast:</label>
        <div class="custom-select">
            <select id="connectionIdQuoteFast" v-model="formData.connectionIdQuoteFast">
                
                <option value="">Select an option</option>
                <option v-for="option in connectionsList" :key="option.id" :value="option.id">{{ option.alias }}</option>
            </select>
            <div class="select-icon">
                <i class="fas fa-chevron-down"></i>
            </div>
        </div>
      </div>
      <div class="form-group">
        <label for="legId">Leg Id:</label>
        <div class="custom-select">
            <select id="legId" v-model="formData.legId">
                
                <option value="">Select an option</option>
                <option v-for="option in legsList" :key="option.id" :value="option.id">{{ option.alias }}</option>
            </select>
            <div class="select-icon">
                <i class="fas fa-chevron-down"></i>
            </div>
        </div>
        </div>
        <div class="form-row">

            <button :disabled="isLoading" type="submit">
                <span v-if="!isLoading">Save</span>
                <span v-else>
                    <i class="fas fa-spinner fa-spin"></i> Loading...
                </span>
            
            </button>  
            <button style="background-color: red;" :disabled="isLoading" @click=cancelEdit class="btn">Cancel</button>
        </div>
    </form>
  </template>
  
  <script>
  
  export default {
    name: 'EditInstancesForm',
    props: {
      isLoading: Boolean, // Pass the item data as a prop
      connectionsList: Array,
      legsList: Array,
      instance: Object
    },
    data() {
      return {
        formData: {
            alias: this.instance.alias,
            connectionIdTrade: this.instance.connectionIdTrade,
            connectionIdQuoteSlow: this.instance.connectionIdQuoteSlow,
            connectionIdQuoteFast: this.instance.connectionIdQuoteFast,
            legId: this.instance.legId
          
        },
      };  
  },
  methods: {
    async submitForm() {
        if (this.isLoading) {
          return; // Prevent multiple submissions while loading
        }
      
      
        const instance = {
            id: this.instance.id,
            alias: this.formData.alias,
            connectionIdTrade: this.formData.connectionIdTrade,
            connectionIdQuoteSlow: this.formData.connectionIdQuoteSlow,
            connectionIdQuoteFast: this.formData.connectionIdQuoteFast,
            legId: this.formData.legId
        };
        
        this.$emit('edit-instance', instance);
        // Handle successful authentication (e.g., store user token, redirect, etc.)
        
      },
      async cancelEdit(){
        this.$emit('cancel-edit');
      }
    }
  };
  </script>
  
  <style scoped>

.title {
  font-size: 32px; /* Adjust the font size as needed */
  font-weight: bold; /* Make the title bold */
  color: #007BFF; /* Title color */
  text-align: center; /* Center the title horizontally */
  margin-bottom: 20px; /* Add some spacing below the title */
  text-transform: uppercase; /* Convert text to uppercase (optional) */
}
.form-row {
  display: flex;
  justify-content: space-between; /* Add space between items */
  align-items: center; /* Center items vertically */
  
}

form {
  width: 100%;
  max-width: 400px; /* Adjust the width as needed */
  margin: 0 auto;
  /* display: flex; */
  justify-content: center;
  align-items: center;
  /* height: 100vh; */
  background-color: #ffffff; /* Form background color */
  border-radius: 8px; /* Rounded corners for the form */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Box shadow for a subtle depth effect */
  padding: 20px;
}

.form-group {
  margin-bottom: 15px;
  width: 100%;
}

label {
  display: block;
  margin-bottom: 5px;
}

input[type="text"],
input[type="number"],
input[type="password"],
input[type="checkbox"] {
  width: 95%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 3px;
}

button {
  display: block;
  width: 100%;
  padding: 10px;
  background-color: #007BFF;
  color: #fff;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}

.custom-select {
  position: relative;
  display: inline-block;
  width: 100%;
}

select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
  appearance: none; /* Remove default arrow */
  -webkit-appearance: none;
  -moz-appearance: none;
  cursor: pointer;
}

.select-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  pointer-events: none;
}

/* Style for the down arrow icon (you can use your own icon library) */
.select-icon i {
  font-size: 16px;
  color: #555;
}

/* Style when hovering over the select */
select:hover {
  border-color: #007BFF; /* Change the border color on hover */
}
</style>