<template>
  <div class="page-container">

    <div class="login">
      <form @submit.prevent="login">
        <h2 class="page-title">Login</h2>
        <div class="form-group">
          <label for="email">Email:</label>
          <input type="email" id="email" placeholder="user@example.com" v-model="email" required>
        </div>
        <div class="form-group">
          <label for="password">Password:</label>
          <input type="password" id="password" placeholder="******" v-model="password" required>
        </div>
        <button :disabled="isLoading" type="submit">
          <span v-if="!isLoading">Submit</span>
          <span v-else>
            <i class="fas fa-spinner fa-spin"></i> Loading...
          </span>
        </button>
      </form>
    </div>
  </div>
  </template>
  
  <script>
  import api from '../interceptors/axiosInterceptos'; 
  import Swal from 'sweetalert2';
  export default {
    data() {
      return {
        email: '',
        password: '',
        isLoading: false,
      };
    },
    methods: {
      async login() {
        if (this.isLoading) {
          return; // Prevent multiple submissions while loading
        }
        try {
          this.isLoading = true;
          const response = await api.post(`${process.env.API_URL}/auth/login`, {
            email: this.email,
            password: this.password,
          });
          if(response.status == 200){
            console.log(response.data.token);
            const token = response.data.token;

            // Store the token in localStorage
            localStorage.setItem('token', token);
            // Inside a component method or event handler
            this.$router.push('/connections'); // Or use a custom route if defined
            this.isLoading = false;

          } else {
              // Handle authentication error (e.g., display error message)
              
              Swal.fire({
                icon: 'error',
                title: 'Error!',
                text: response.statusText,
              })
              this.isLoading = false;
            }
          // Handle successful authentication (e.g., store user token, redirect, etc.)
          this.isLoading = false;
        } catch (error) {
          Swal.fire({
            icon: 'error',
            title: 'Error!',
            text: error.response.statusText,
          })
              
            
          // Handle authentication error (e.g., display error message)
          this.isLoading = false;
        }
      },
      

    },
  };
  </script>
  
  <style scoped>

.page-title {
  font-size: 32px; /* Adjust the font size as needed */
  font-weight: bold; /* Make the title bold */
  color: #007BFF; /* Title color */
  text-align: center; /* Center the title horizontally */
  margin-bottom: 20px; /* Add some spacing below the title */
  text-transform: uppercase; /* Convert text to uppercase (optional) */
}
  .login {
    display: flex;
    justify-content: center;
    align-items: center;
    /* height: 100vh; */
    background-color: #ffffff; /* Form background color */
    border-radius: 8px; /* Rounded corners for the form */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Box shadow for a subtle depth effect */
    padding: 20px;
    max-width: 400px; /* Adjust the maximum width as needed */
    width: 100%;
  }
  
  form {
    width: 100%;
    max-width: 300px;
    padding: 20px;
    /* border: 1px solid #ccc; */
    border-radius: 5px;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  label {
    display: block;
    margin-bottom: 5px;
  }
  
  input {
    width: 95%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 3px;
  }
  
  button {
    display: block;
    width: 100%;
    padding: 10px;
    background-color: #007BFF;
    color: #fff;
    border: none;
    border-radius: 3px;
    cursor: pointer;
    
  }

  .page-container {
  background-image: linear-gradient(to bottom, #f0f0f0, #e0e0e0); /* Background gradient */
  min-height: 100vh; /* Ensure the background covers the entire viewport height */
  display: flex;
  justify-content: center;
  align-items: center;
}
  </style>
  