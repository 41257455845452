<!-- CardComponent.vue -->
<template>
      <div class="card">
        <div class="card-header">
          <h2>{{ cardData.alias }}</h2>
          <div class="actions">
            <button style="background-color: green;" @click="editItem(cardData)">Edit</button>
            <button style="background-color: red;" @click="deleteItem(cardData.id)">Delete</button>
          </div>
        </div>
        <div class="card-content">
          <p><strong>Server:</strong> {{ cardData.server }}</p>
          <p><strong>Port:</strong> {{ cardData.port }}</p>
          <p><strong>Trade:</strong> {{ cardData.trade ? 'Yes' : 'No' }}</p>
        </div>
      </div>
  </template>
  
  <script>
  
  export default {
    name: 'CardComponent',
    props: {
      cardData: Object, // Pass the item data as a prop
    },
    methods: {
      async deleteItem(id) {
        this.$emit('delete-connection', id);
      },
      async editItem(connection) {
        this.$emit('edit-connection', connection);
      }
    },
    
  };
  </script>
  
  <style scoped>
.card {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 15px;
  margin: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.actions {
  display: flex;
  gap: 10px;
}

button {
  background-color: #007BFF;
  color: #fff;
  border: none;
  border-radius: 3px;
  padding: 5px 10px;
  cursor: pointer;
}

.card-content {
  margin-top: 10px;
}

/* Add additional styling as needed */
</style>