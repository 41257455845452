<!-- WebSocketComponent.vue -->
<template>
  <div class="websocket-container">
    <div class="message-container" ref="messageContainer">
      <div v-for="(message, index) in messages" :key="index" class="message">
        {{ message }}
      </div>
    </div>
  </div>
  <!-- Add labels for bid and ask below the container -->
  <div class="price-label">
    <p>{{sendercompid}} || {{ sendercompidFast }}</p>
    <p>Bid: {{ bid }}   || Bid: {{ bidFast }}</p>
    <p>Ask: {{ ask }}   || Ask: {{ askFast }}</p>
    <p>gapBuy: {{ gapBuy }} </p>
    <p>gapSell: {{ gapSell }} </p>
    <p>takeProfitThreshold: {{ takeProfitThreshold }} </p>
    <p>isFastBidIncreased: {{ isFastBidIncreased }} </p>
    <p>spreadDifference: {{ spreadDifference }} </p>
  </div>
</template>

<script>
  export default {
    name: 'WebSocketComponent',
    props: {
      sendercompid: {
        type: String,
        required: true,
      },
      sendercompidFast: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        messages: [],
        socket: null,
        bid: null, // To store the latest bid value
        ask: null, // To store the latest ask value
        askFast: null,
        bidFast: null,
        gapBuy: null,
        gapSell: null,
        takeProfitThreshold: null,
        isFastBidIncreased: null,
        spreadDifference: null,
      };
    },
    mounted() {
      this.initWebSocket();
    },
    beforeUnmount() {
      this.closeWebSocket();
    },
    methods: {
      initWebSocket() {
        this.socket = new WebSocket('wss://fixapi-api.edpfix.mx');

        this.socket.addEventListener('open', (event) => {
          console.log('WebSocket connection opened:', event);
        });

        this.socket.addEventListener('message', (event) => {
          const message = JSON.parse(event.data);
          // Check if the message is from the correct channel
          console.log(this.sendercompidFast)
          if (message.channel === this.sendercompid) {
            const { symbol } = message.message;
            if (symbol) {
              if(symbol.bid !== undefined && symbol.bid !== null) this.bid = symbol.bid;
              if(symbol.ask !== undefined && symbol.ask !== null) this.ask = symbol.ask;
            }
            // Add the raw message to the messages array
          } else if(message.channel === this.sendercompidFast) {
            const { symbol } = message.message;
            if (symbol) {
              this.bidFast = symbol.bid ?? null;
              this.askFast = symbol.ask ?? null;
            }
          } else if(message.channel === 'general-data') {
            const message = JSON.parse(event.data);
            this.gapBuy = message.message.gapBuy
            this.gapSell = message.message.gapSell
            this.takeProfitThreshold = message.message.takeProfitThreshold
            this.isFastBidIncreased = message.message.isFastBidIncreased
            this.spreadDifference = message.message.spreadDifference
          }else {
            this.messages.push(JSON.stringify(message));
            // Scroll to the bottom when a new message is added
            this.$refs.messageContainer.scrollTop = this.$refs.messageContainer.scrollHeight;
          }

        });

        this.socket.addEventListener('close', (event) => {
          console.log('WebSocket connection closed:', event);
        });
      },
      closeWebSocket() {
        if (this.socket) {
          this.socket.close();
        }
      },
    },
  };
</script>

<style scoped>
  .websocket-container {
    width: 1000px;
    height: 400px;
    background-color: black;
    overflow-y: auto;
  }
  
  .message-container {
    padding: 10px;
  }
  
  .message {
    color: white;
    margin-bottom: 10px;
  }

  .price-label {
    margin-top: 10px;
    color: black;
    font-size: 16px;
  }

  .price-label p {
    margin: 5px 0;
  }
</style>
