<template>
    <div class="detailed-view">
      <!-- Information about the object -->
      <div class="object-info">
        <!-- Display object details here -->
      </div>
  
      <!-- Program Instance Management -->
      <div class="program-management">
        <PlayButton :is-running_="isProgramRunning" @start-instance="startProgram"/>
        <button @click="returnAction">
      Regresar
    </button>
      </div>
  
      <!-- Current Logs -->
      <div class="current-logs">
        <h2>Current Logs</h2>
        <div id="app">
          <WebSocketComponent 
            :sendercompid="instance.quoteSlowConnection.sendercompid" 
            :sendercompidFast="instance.quoteFastConnection.sendercompid"/>
        </div>
        <!-- Display current logs here -->
      </div>
  
      <!-- Historical Logs -->
      <div class="historical-logs">
        <h2>Historical Logs</h2>
        <!-- Display historical logs with download links here -->
      </div>
    </div>
  </template>
  
  <script>
  import PlayButton from './PlayButton.vue';
  import WebSocketComponent from './WebSocketComponent.vue';

  export default {
    name: 'InstanceDetail',
    props: {
      instance: Object,
    },
    data() {
      
      return {
        isProgramRunning: this.instance.isRunning,
      };
    },
    components : {
      PlayButton,
      WebSocketComponent
    },
    methods: {
        returnAction(){
            this.$emit('return-instance');
        },
      startProgram() {
        // Implement logic to start the program
        this.$emit('start-instance:instance', this.instance)
      },
    },
  };
  </script>
  
  <style scoped>
  #app {
    text-align: center;
    color: #2c3e50;
    margin-top: 60px;
  }
  /* Add styles for the detailed view components */
  .program-management {
    display: flex;
    gap: 10px;
  }

  button {
background-color: #007BFF;
color: #fff;
border: none;
border-radius: 3px;
padding: 5px 10px;
cursor: pointer;
}

  </style>
  