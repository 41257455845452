import { createRouter, createWebHistory } from 'vue-router'
import LoginForm from '../pages/LoginForm'
import ConnectionsPage from '../pages/ConnectionsPage'
import LegsPage from '../pages/LegsPage'
import InstancesPage from '../pages/InstancesPage'

const routes = [
  {
    path: '/',
    name: 'Login',
    component: LoginForm,
  },
  {
    path: '/connections',
    name: 'Connections Page',
    component: ConnectionsPage,
    meta: { requiresAuth: true },
  },
  {
    path: '/legs',
    name: 'Legs Page',
    component: LegsPage,
    meta: { requiresAuth: true },
  },
  {
    path: '/instances',
    name: 'Instances Page',
    component: InstancesPage,
    meta: { requiresAuth: true },
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

router.beforeEach((to, from, next) => {
  const isAuthenticated = localStorage.getItem('token');

  if (to.meta.requiresAuth && !isAuthenticated) {
    // Redirect to the login page if the route requires authentication
    next('/');
  } else {
    next();
  }
});

export default router