<!-- FormComponent.vue -->
<template>
    <form @submit.prevent="submitForm">
      <h2 class="title">Edit Connection</h2>
      <div class="form-group">
        <label for="alias">Alias:</label>
        <input type="text" id="alias" v-model="formData.alias" required>
      </div>
      <div class="form-group">
        <label for="server">Server:</label>
        <input type="text" id="server" v-model="formData.server" required>
      </div>
      <div class="form-group">
        <label for="port">Port:</label>
        <input type="number" id="port" v-model="formData.port" required>
      </div>
      <div class="form-group">
        <label for="username">Username:</label>
        <input type="text" id="username" v-model="formData.username" required>
      </div>
      <div class="form-group">
        <label for="password">Password:</label>
        <input type="password" id="password" v-model="formData.password" required>
      </div>
      <div class="form-group">
        <label for="sendercompid">SenderCompID:</label>
        <input type="text" id="sendercompid" v-model="formData.sendercompid" required>
      </div>
      <div class="form-group">
        <label for="targetcompid">TargetCompID:</label>
        <input type="text" id="targetcompid" v-model="formData.targetcompid" required>
      </div>
      <div class="form-group">
        <label for="targetcompid">Account:</label>
        <input type="text" id="account" v-model="formData.account" required>
      </div>
      <div class="form-row">
        <div class="form-group">
          <label for="trade">Trade Connection:</label>
          <input type="checkbox" id="trade" v-model="formData.trade">
        </div>
        <div class="form-group">
          <label for="useTLS">Use TLS:</label>
          <input type="checkbox" id="useTLS" v-model="formData.useTLS">
        </div>
      </div>
      <div class="form-row">

        <button :disabled="isLoading" type="submit">
          <span v-if="!isLoading">Save</span>
          <span v-else>
            <i class="fas fa-spinner fa-spin"></i> Loading...
          </span>
        </button>
        <button style="background-color: red;" :disabled="isLoading" @click=cancelEdit class="btn">Cancel</button>
      </div>
    </form>
  </template>
  
  <script>
  
  export default {
    name: 'EditConnectionForm',
    props: {
        isLoading: Boolean, // Pass the item data as a prop
        connection: Object
    },
    data() {
      return {
        formData: {
          alias: this.connection.alias,
          server: this.connection.server,
          port: this.connection.port,
          username: this.connection.username,
          password: this.connection.password,
          sendercompid: this.connection.sendercompid,
          targetcompid: this.connection.targetcompid,
          useTLS: this.connection.useTLS,
          account: this.connection.account,
          trade: this.connection.trade,
          
        },
      };  
  },
  methods: {
    async submitForm() {
        if (this.isLoading) {
          return; // Prevent multiple submissions while loading
        }
      
      
        const connection = {
          id: this.connection.id,
          alias: this.formData.alias,
          server: this.formData.server,
          port: this.formData.port,
          username: this.formData.username,
          password: this.formData.password,
          sendercompid: this.formData.sendercompid,
          targetcompid: this.formData.targetcompid,
          useTLS: this.formData.useTLS,
          account: this.formData.account,
          trade: this.formData.trade,
        };
        this.$emit('edit-connection', connection);
        // Handle successful authentication (e.g., store user token, redirect, etc.)
        
      },
      async cancelEdit(){
        this.$emit('cancel-edit');
      }
    }
  };
  </script>
  
  <style scoped>

.actions {
  display: flex;
  gap: 10px;
}
.title {
  font-size: 32px; /* Adjust the font size as needed */
  font-weight: bold; /* Make the title bold */
  color: #007BFF; /* Title color */
  text-align: center; /* Center the title horizontally */
  margin-bottom: 20px; /* Add some spacing below the title */
  text-transform: uppercase; /* Convert text to uppercase (optional) */
}
.form-row {
  display: flex;
  justify-content: space-between; /* Add space between items */
  align-items: center; /* Center items vertically */
  gap: 10px;
}

form {
  width: 100%;
  max-width: 400px; /* Adjust the width as needed */
  margin: 0 auto;
  /* display: flex; */
  justify-content: center;
  align-items: center;
  /* height: 100vh; */
  background-color: #ffffff; /* Form background color */
  border-radius: 8px; /* Rounded corners for the form */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Box shadow for a subtle depth effect */
  padding: 20px 20px ;
  
  width: 100%;
}

.form-group {
  margin-bottom: 15px;
}

label {
  display: block;
  margin-bottom: 5px;
}

input[type="text"],
input[type="number"],
input[type="password"],
input[type="checkbox"] {
  width: 95%;
  
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 3px;
}

button {
  display: block;
  width: 100%;
  padding: 10px;
  background-color: #007BFF;
  color: #fff;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}
</style>