<!-- FormComponent.vue -->
<template>
    <form @submit.prevent="submitForm">
      <h2 class="title">Create Connection</h2>
      <div class="form-group">
        <label for="alias">Alias:</label>
        <input type="text" id="alias" placeholder="alias" v-model="formData.alias" required>
      </div>
      <div class="form-group">
        <label for="server">Server:</label>
        <input type="text" id="server" placeholder="server.example.com / 127.0.0.1" v-model="formData.server" required>
      </div>
      <div class="form-group">
        <label for="port">Port:</label>
        <input type="number" step="1" id="port" placeholder="12345" v-model="formData.port" required>
      </div>
      <div class="form-group">
        <label for="username">Username:</label>
        <input type="text" id="username" placeholder="username" v-model="formData.username" required>
      </div>
      <div class="form-group">
        <label for="password">Password:</label>
        <input type="password" id="password" placeholder="********" v-model="formData.password" required>
      </div>
      <div class="form-group">
        <label for="sendercompid">SenderCompID:</label>
        <input type="text" id="sendercompid" placeholder="X000" v-model="formData.sendercompid" required>
      </div>
      <div class="form-group">
        <label for="targetcompid">TargetCompID:</label>
        <input type="text" id="targetcompid" placeholder="Y000" v-model="formData.targetcompid" required>
      </div>
      <div class="form-group">
        <label for="targetcompid">Account:</label>
        <input type="text" id="account" placeholder="12345678" v-model="formData.account" required>
      </div>
      <div class="form-row">
        <div class="form-group">
          <label for="trade">Trade Connection:</label>
          <input type="checkbox" id="trade" v-model="formData.trade">
        </div>
        <div class="form-group">
          <label for="useTLS">Use TLS:</label>
          <input type="checkbox" id="useTLS" v-model="formData.useTLS">
        </div>
      </div>
      <button :disabled="isLoading" type="submit">
        <span v-if="!isLoading">Submit</span>
        <span v-else>
          <i class="fas fa-spinner fa-spin"></i> Loading...
        </span>
      </button>
    </form>
  </template>
  
  <script>
  
  export default {
    name: 'FormComponent',
    props: {
      isLoading: Boolean, // Pass the item data as a prop
    },
    data() {
      return {
        formData: {
          alias: '',
          server: '',
          port: null,
          username: '',
          password: '',
          sendercompid: '',
          targetcompid: '',
          useTLS: false,
          account: '',
          trade: false,
          
        },
      };  
  },
  methods: {
    async submitForm() {
        if (this.isLoading) {
          return; // Prevent multiple submissions while loading
        }
      
      
        const connection = {
          alias: this.formData.alias,
          server: this.formData.server,
          port: this.formData.port,
          username: this.formData.username,
          password: this.formData.password,
          sendercompid: this.formData.sendercompid,
          targetcompid: this.formData.targetcompid,
          useTLS: this.formData.useTLS,
          account: this.formData.account,
          trade: this.formData.trade,
        };
        
        this.$emit('add-connection', connection);
        // Handle successful authentication (e.g., store user token, redirect, etc.)
        
      }
    }
  };
  </script>
  
  <style scoped>
  .title {
  font-size: 32px; /* Adjust the font size as needed */
  font-weight: bold; /* Make the title bold */
  color: #007BFF; /* Title color */
  text-align: center; /* Center the title horizontally */
  margin-bottom: 20px; /* Add some spacing below the title */
  text-transform: uppercase; /* Convert text to uppercase (optional) */
}
.form-row {
  display: flex;
  justify-content: space-between; /* Add space between items */
  align-items: center; /* Center items vertically */
}

form {
  width: 100%;
  max-width: 400px; /* Adjust the width as needed */
  margin: 0 auto;
  /* display: flex; */
  justify-content: center;
  align-items: center;
  /* height: 100vh; */
  background-color: #ffffff; /* Form background color */
  border-radius: 8px; /* Rounded corners for the form */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Box shadow for a subtle depth effect */
  padding: 20px;
}

.form-group {
  margin-bottom: 15px;
}

label {
  display: block;
  margin-bottom: 5px;
}

input[type="text"],
input[type="number"],
input[type="password"],
input[type="checkbox"] {
  width: 95%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 3px;
}

button {
  display: block;
  width: 100%;
  padding: 10px;
  background-color: #007BFF;
  color: #fff;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}


</style>