<!-- src/App.vue -->
<template>
  <!-- <SideBar /> -->
  <div id="app" class="content">

    <router-view></router-view>
  </div>
</template>

<script>

// import SideBar from './components/sidebar/SideBar';

export default {
  components: {
    // SideBar
  },
};
</script>
