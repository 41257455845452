<!-- CardComponent.vue -->
<template>
    <div class="card">
      <div class="card-header">
        <h2>{{ cardData.alias }}</h2>
        <div class="actions">
          <button :disabled="isLoading || cardData.isRunning" style="background-color: rgb(2, 142, 250);" @click="testItem(cardData.id)">
            <span v-if="!isLoading">Test</span>
            <span v-else>
              <i class="fas fa-spinner fa-spin"></i> Loading...
            </span>
          </button>
          <button :disabled="isLoading" style="background-color: blue;" @click="detailItem(cardData)">Detalle</button>
          <button :disabled="isLoading || cardData.isRunning" style="background-color: green;" @click="editItem(cardData)">Edit</button>
          <button :disabled="isLoading || cardData.isRunning" style="background-color: red;" @click="deleteItem(cardData.id)">Delete</button>
        </div>
      </div>
      <div class="card-content">
        <div class="two-column-layout">
            <div class="card-column"> 
                <p><strong>Trade Connection:</strong> </p>
                <p><strong>Slow Connection:</strong> </p>
                <p><strong>Fast Connection:</strong> </p>
                <p><strong>Symbol Configuration:</strong> </p>
            </div>
            <div class="card-column"> 
                <p><strong>Start Time:</strong> </p>
                <p><strong>End Time:</strong> </p>
                <button :disabled="isLoading" class="toggle-button" :class="{ stop: cardData.isRunning }" @click="toggleAction(cardData)">
                  <span v-if="!isLoading">{{ cardData.isRunning ? 'Stop' : 'Start' }}</span>
                  <span v-else>
                    <i class="fas fa-spinner fa-spin"></i> Loading...
                  </span>
                </button>
            </div>
        </div>
        
      </div>
    </div>
</template>

<script>

export default {
  name: 'InstanceCard',
  props: {
    isLoading: Boolean,
    cardData: Object, // Pass the item data as a prop
  },
  components : {
    
  },
  methods: {
    async toggleAction(instance) {
      this.$emit('start-instance', instance)
      },
    async testItem(id) {
        this.$emit('test-instance', id);
      },
    async deleteItem(id) {
      this.$emit('delete-instance', id);
    },
    async editItem(leg) {
      this.$emit('edit-instance', leg);
    },
    async detailItem(id) {
      this.$emit('detail-instance', id);
    },
  },
  
};
</script>

<style scoped>
 .two-column-layout {
    display: flex; /* Use flexbox to create the two-column layout */
    flex-wrap: wrap; /* Allow columns to wrap on smaller screens */
  }
  .card-column {
    flex: 1; /* Use flex to make the form column flexible */
    padding: 20px; /* Add padding for spacing */
  }
.card {
border: 1px solid #ccc;
border-radius: 5px;
padding: 15px;
margin: 10px;
box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
background-color: #fff;
}

.card-header {
display: flex;
justify-content: space-between;
align-items: center;
}

.actions {
display: flex;
gap: 10px;
}

button {
background-color: #007BFF;
color: #fff;
border: none;
border-radius: 3px;
padding: 5px 10px;
cursor: pointer;
}

.card-content {
margin-top: 10px;
}

.card-row {
  display: flex;
  justify-content: space-between; /* Add space between items */
  align-items: center; /* Center items vertically */
}

.toggle-button {
    background-color: #4CAF50; /* Green background color for Start */
    color: white;
    /* ... (other styles) ... */
  }
  
  .toggle-button:hover {
    background-color: #45a049; /* Darker green color on hover */
  }
  
  .toggle-button.stop {
    background-color: #f44336; /* Red background color for Stop */
  }


/* Add additional styling as needed */
</style>