<!-- FormComponent.vue -->
<template>
    <form @submit.prevent="submitForm">
      <h2 class="title">Edit Leg</h2>
      <div class="form-row">
        <div class="form-group">
          <label for="alias">Alias:</label>
          <input type="text" id="alias" v-model="formData.alias" required>
        </div>
        <div class="form-group">
          <label for="Symbol">Symbol:</label>
          <input type="text" id="Symbol" v-model="formData.Symbol" required>
        </div>

      </div>
      <div class="form-row">
        <div class="form-group">
            <label for="startTime">Start Time:</label>
            <input type="time" id="startTime" v-model="formData.startTime" required>
        </div>
        <div class="form-group">
            <label for="endTime">End Time:</label>
            <input type="time" id="endTime" v-model="formData.endTime" required>
        </div>
        </div>
      <div class="form-row">
        <div class="form-group">
          <label for="UseAverageSpread">Use Average Spread:</label>
          <input type="checkbox" id="UseAverageSpread" v-model="formData.UseAverageSpread">
        </div>
        <div class="form-group">
          <label for="AvtoSettings">Avto Settings:</label>
          <input type="checkbox" id="AvtoSettings" v-model="formData.AvtoSettings">
        </div>
      </div>
      <div class="form-row">
        <div class="form-group">
            <label for="MinimumLevel">Minimum Level:</label>
            <input type="number" step="1" id="MinimumLevel" v-model="formData.MinimumLevel" required>
        </div>
        <div class="form-group">
            <label for="MinimumLevelClose">Minimum Level Close:</label>
            <input type="number" step="1" id="MinimumLevelClose" v-model="formData.MinimumLevelClose" required>
        </div>
    </div>
    <div class="form-row">

        <div class="form-group">
            <label for="FixTP">Fix TP:</label>
            <input type="number" step="1" id="FixTP" v-model="formData.FixTP" required>
        </div>
        <div class="form-group">
            <label for="FixSL">Fix SL:</label>
            <input type="number" step="1" id="FixSL" v-model="formData.FixSL" required>
        </div>
    </div>
    <div class="form-row">
        <div class="form-group">
            <label for="FixTrailStart">Fix Trail Start:</label>
            <input type="number" id="FixTrailStart" v-model="formData.FixTrailStart" required>
            
        </div>
        <div class="form-group">
            <label for="FixTrailStop">Fix Trail Stop:</label>
            <input type="number" step="1" id="FixTrailStop" v-model="formData.FixTrailStop" required>
        </div>
    </div>
    <div class="form-row">

        <div class="form-group">
            <label for="MinSpread">Min Spread:</label>
            <input type="number" step="1" id="MinSpread" v-model="formData.MinSpread" required>
        </div>
        <div class="form-group">
            <label for="MinSpreadSlow">Min Spread Slow:</label>
            <input type="number" step="1" id="MinSpreadSlow" v-model="formData.MinSpreadSlow" required>
        </div>
        <div class="form-group">
            <label for="MaxSpreadSlow">Max Spread Slow:</label>
            <input type="number" step="1" id="MaxSpreadSlow" v-model="formData.MaxSpreadSlow" required>
        </div>
    </div>
      <div class="form-group">
        <label for="SignalMode">Signal Mode:</label>
        <input type="number" step="1" id="SignalMode" v-model="formData.SignalMode" required>
      </div>
      <div class="form-row">

          <div class="form-group">
              <label for="ShiftBid">Shift Bid:</label>
              <input type="number" step="1" id="ShiftBid" v-model="formData.ShiftBid" required>
            </div>
            <div class="form-group">
                <label for="ShiftAsk">Shift Ask:</label>
                <input type="number" step="1" id="ShiftAsk" v-model="formData.ShiftAsk" required>
            </div>
        </div>
      <div class="form-group">
        <label for="Lot">Lot:</label>
        <input type="number" step="0.01" id="Lot" v-model="formData.Lot" required>
      </div>
      <div class="form-group">
        <label for="RiskPercent">Risk Percent:</label>
        <input type="number" step="1" id="RiskPercent" v-model="formData.RiskPercent" required>
      </div>
      <div class="form-row">

          <div class="form-group">
              <label for="FreezeTime">Freeze Time:</label>
              <input type="number" step="1" id="FreezeTime" v-model="formData.FreezeTime" required>
            </div>
            <div class="form-group">
                <label for="MinimumOrderTimeSec">Minimum Order Time Sec:</label>
                <input type="number" step="1" id="MinimumOrderTimeSec" v-model="formData.MinimumOrderTimeSec" required>
            </div>
        </div>
        <div class="form-group">
          <label for="CloseTimer">Close Timer:</label>
          <input type="number" step="1" id="CloseTimer" v-model="formData.CloseTimer" required>
        </div>
        <div class="form-group">
            <label for="NumberOfTrades">Number Of Trades:</label>
            <input type="number" step="1" id="NumberOfTrades" v-model="formData.NumberOfTrades" required>
        </div>
      <div class="form-row">

          <div class="form-group">
              <label for="MinGapForOpen">Min Gap For Open:</label>
              <input type="number" step="1" id="MinGapForOpen" v-model="formData.MinGapForOpen" required>
            </div>
            <div class="form-group">
                <label for="MaxGapForOpen">Max Gap For Open:</label>
                <input type="number" step="1" id="MaxGapForOpen" v-model="formData.MaxGapForOpen" required>
            </div>
        </div>
        <div class="form-row">
            <div class="form-group">
                
                <label for="MaxAmountOfLostTradesPerDay">Max Amount Of Lost Trades Per Day:</label>
                <input type="number" step="1" id="MaxAmountOfLostTradesPerDay" v-model="formData.MaxAmountOfLostTradesPerDay" required>
            </div>
            <div class="form-group">
                <label for="MaxAmountOfMoneyLostPerDay">Max Amount Of Money Lost Per Day:</label>
                <input type="number" step="1" id="MaxAmountOfMoneyLostPerDay" v-model="formData.MaxAmountOfMoneyLostPerDay" required>
            </div>
        </div>
      <div class="form-row">
        <div class="form-group">
          <label for="AvtoShiftBid">Avto Shif tBid:</label>
          <input type="checkbox" id="AvtoShiftBid" v-model="formData.AvtoShiftBid">
        </div>
        <div class="form-group">
          <label for="TrailingEnabled">Trailing Enabled:</label>
          <input type="checkbox" id="TrailingEnabled" v-model="formData.TrailingEnabled">
        </div>
      </div>
      <div class="form-row">

        <button :disabled="isLoading" type="submit">
          <span v-if="!isLoading">Save</span>
          <span v-else>
            <i class="fas fa-spinner fa-spin"></i> Loading...
          </span>
        </button>
        <button style="background-color: red;" :disabled="isLoading" @click=cancelEdit class="btn">Cancel</button>
      </div>
    </form>
  </template>
  
  <script>
  
  export default {
    name: 'LegEditForm',
    props: {
      isLoading: Boolean, // Pass the item data as a prop
      leg: Object
    },
    data() {
      return {
        formData: {
            alias: this.leg.alias,
            startTime: this.leg.startTime,
            endTime: this.leg.endTime,
            Symbol: this.leg.Symbol,
            UseAverageSpread: this.leg.UseAverageSpread,
            AvtoSettings: this.leg.AvtoSettings,
            MinimumLevel: this.leg.MinimumLevel,
            MinimumLevelClose: this.leg.MinimumLevelClose,
            FixTP: this.leg.FixTP,
            FixSL: this.leg.FixSL,
            FixTrailStart: this.leg.FixTrailStart,
            FixTrailStop: this.leg.FixTrailStop,
            MinSpread: this.leg.MinSpread,
            MinSpreadSlow: this.leg.MinSpreadSlow,
            MaxSpreadSlow: this.leg.MaxSpreadSlow,
            AvtoShiftBid: this.leg.AvtoShiftBid,
            SignalMode: this.leg.SignalMode,
            ShiftBid: this.leg.ShiftBid,
            ShiftAsk: this.leg.ShiftAsk,
            Lot: this.leg.Lot,
            RiskPercent: this.leg.RiskPercent,
            FreezeTime: this.leg.FreezeTime,
            MinGapForOpen: this.leg.MinGapForOpen,
            MaxGapForOpen: this.leg.MaxGapForOpen,
            NumberOfTrades: this.leg.NumberOfTrades,
            MinimumOrderTimeSec: this.leg.MinimumOrderTimeSec,
            CloseTimer: this.leg.CloseTimer,
            TrailingEnabled: this.leg.TrailingEnabled,
            MaxAmountOfLostTradesPerDay: this.leg.MaxAmountOfLostTradesPerDay,
            MaxAmountOfMoneyLostPerDay: this.leg.MaxAmountOfMoneyLostPerDay
          
        },
      };  
  },
  methods: {
    async submitForm() {
        if (this.isLoading) {
          return; // Prevent multiple submissions while loading
        }
      
      
        const leg = {
            id: this.leg.id,
            alias: this.formData.alias,
            startTime: this.formData.startTime,
            endTime: this.formData.endTime,
            Symbol: this.formData.Symbol,
            UseAverageSpread: this.formData.UseAverageSpread,
            AvtoSettings: this.formData.AvtoSettings,
            MinimumLevel: this.formData.MinimumLevel,
            MinimumLevelClose: this.formData.MinimumLevelClose,
            FixTP: this.formData.FixTP,
            FixSL: this.formData.FixSL,
            FixTrailStart: this.formData.FixTrailStart,
            FixTrailStop: this.formData.FixTrailStop,
            MinSpread: this.formData.MinSpread,
            MinSpreadSlow: this.formData.MinSpreadSlow,
            MaxSpreadSlow: this.formData.MaxSpreadSlow,
            AvtoShiftBid: this.formData.AvtoShiftBid,
            SignalMode: this.formData.SignalMode,
            ShiftBid: this.formData.ShiftBid,
            ShiftAsk: this.formData.ShiftAsk,
            Lot: this.formData.Lot,
            RiskPercent: this.formData.RiskPercent,
            FreezeTime: this.formData.FreezeTime,
            MinGapForOpen: this.formData.MinGapForOpen,
            MaxGapForOpen: this.formData.MaxGapForOpen,
            NumberOfTrades: this.formData.NumberOfTrades,
            MinimumOrderTimeSec: this.formData.MinimumOrderTimeSec,
            CloseTimer: this.formData.CloseTimer,
            TrailingEnabled: this.formData.TrailingEnabled,
            MaxAmountOfLostTradesPerDay: this.formData.MaxAmountOfLostTradesPerDay,
            MaxAmountOfMoneyLostPerDay: this.formData.MaxAmountOfMoneyLostPerDay
        };
        
        this.$emit('edit-leg', leg);
        // Handle successful authentication (e.g., store user token, redirect, etc.)
        
      },
      async cancelEdit(){
        this.$emit('cancel-edit');
      }
    }
  };
  </script>
  
  <style scoped>

.title {
  font-size: 32px; /* Adjust the font size as needed */
  font-weight: bold; /* Make the title bold */
  color: #007BFF; /* Title color */
  text-align: center; /* Center the title horizontally */
  margin-bottom: 20px; /* Add some spacing below the title */
  text-transform: uppercase; /* Convert text to uppercase (optional) */
}
.form-row {
  display: flex;
  justify-content: space-between; /* Add space between items */
  align-items: center; /* Center items vertically */
  padding-right: 10px;
  gap: 10px;
}

form {
  width: 100%;
  max-width: 400px; /* Adjust the width as needed */
  margin: 0 auto;
  /* display: flex; */
  justify-content: center;
  align-items: center;
  /* height: 100vh; */
  background-color: #ffffff; /* Form background color */
  border-radius: 8px; /* Rounded corners for the form */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Box shadow for a subtle depth effect */
  padding: 20px;
}

.form-group {
  margin-bottom: 15px;
  
}

label {
  display: block;
  margin-bottom: 5px;
}
input[type="time"] {
  /* Customize the appearance of the time input */
  /* Example styles: */
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 14px;
  width: 150px;
}

input[type="text"],
input[type="number"],
input[type="password"],
input[type="checkbox"] {
  width: 95%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 3px;
}

button {
  display: block;
  width: 100%;
  padding: 10px;
  background-color: #007BFF;
  color: #fff;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}
</style>