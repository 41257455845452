<!-- CardComponent.vue -->
<template>
    <div class="card">
      <div class="card-header">
        <h2>{{ cardData.alias }}</h2>
        <div class="actions">
          <button style="background-color: green;" @click="editItem(cardData)">Edit</button>
          <button style="background-color: red;" @click="deleteItem(cardData.id)">Delete</button>
        </div>
      </div>
      <div class="card-content">
        <div class="two-column-layout">

            <div class="card-column"> 
                <p><strong>Start Time:</strong> {{ cardData.startTime }}</p>
                <p><strong>End Time:</strong> {{ cardData.endTime }}</p>
            </div>
            <div class="card-column">    
                <p><strong>Fix TP:</strong> {{ cardData.FixTP }}</p>
                <p><strong>Fix SL:</strong> {{ cardData.FixSL }}</p>
            </div>
            <div class="card-column">

                <p><strong>Lot:</strong> {{ cardData.Lot }}</p>
                <p><strong>Symbol:</strong> {{ cardData.Symbol }}</p>
            </div>
        </div>
        
      </div>
    </div>
</template>

<script>

export default {
  name: 'LegCard',
  props: {
    cardData: Object, // Pass the item data as a prop
  },
  methods: {
    async deleteItem(id) {
      this.$emit('delete-leg', id);
    },
    async editItem(leg) {
      this.$emit('edit-leg', leg);
    }
  },
  
};
</script>

<style scoped>
 .two-column-layout {
    display: flex; /* Use flexbox to create the two-column layout */
    flex-wrap: wrap; /* Allow columns to wrap on smaller screens */
  }
  .card-column {
    flex: 1; /* Use flex to make the form column flexible */
    padding: 20px; /* Add padding for spacing */
  }
.card {
border: 1px solid #ccc;
border-radius: 5px;
padding: 15px;
margin: 10px;
box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
background-color: #fff;
}

.card-header {
display: flex;
justify-content: space-between;
align-items: center;
}

.actions {
display: flex;
gap: 10px;
}

button {
background-color: #007BFF;
color: #fff;
border: none;
border-radius: 3px;
padding: 5px 10px;
cursor: pointer;
}

.card-content {
margin-top: 10px;
}

.card-row {
  display: flex;
  justify-content: space-between; /* Add space between items */
  align-items: center; /* Center items vertically */
}


/* Add additional styling as needed */
</style>