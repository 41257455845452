<template>
    <button class="toggle-button" :class="{ stop: isRunning }" @click="toggleAction">
      {{ isRunning ? 'Stop' : 'Start' }}
    </button>
  </template>
  
  <script>
  export default {
    props: {
      isRunning_: Boolean
    },
    data() {
      return {
        isRunning: this.isRunning_,
      };
    },
    methods: {
      toggleAction() {
        this.isRunning = !this.isRunning; // Toggle the state
        
        this.$emit('start-instance')
        
      },
    },
  };
  </script>
  
  <style scoped>
  .toggle-button {
    background-color: #4CAF50; /* Green background color for Start */
    color: white;
    /* ... (other styles) ... */
  }
  
  .toggle-button:hover {
    background-color: #45a049; /* Darker green color on hover */
  }
  
  .toggle-button.stop {
    background-color: #f44336; /* Red background color for Stop */
  }
  </style>
  