<!-- HamburgerMenu.vue -->
<template>
    <div class="hamburger-menu">
      <div class="menu-icon" @click="toggleMenu">
        <div class="bar"></div>
        <div class="bar"></div>
        <div class="bar"></div>
      </div>
      <ul class="menu-list" :class="{ 'active': isOpen }">
        <li @click="navigateTo('connections')">Connections</li>
        <li @click="navigateTo('legs')">Legs</li>
        <li @click="navigateTo('instances')">Instances</li>
        <li @click="logout">Logout</li>
      </ul>
    </div>
  </template>
  
  <script>
  export default {
    name: "HamburgerMenu",
    data() {
      return {
        isOpen: false,
      };
    },
    methods: {
      toggleMenu() {
        this.isOpen = !this.isOpen;
      },
      navigateTo(route) {
        // Implement navigation logic based on the selected route
        this.$router.push('/' + route); // Example: Push the route to the router
        this.toggleMenu(); // Close the menu after selecting a route
      },
      logout() {
        // Remove the token from local storage
        localStorage.removeItem('token');

        // Redirect the user to the login page or another destination
        this.$router.push('/'); // Adjust the route as needed
        this.toggleMenu(); // Close the menu after logging out
      },
    },
  };
  </script>
  
  <style scoped>
  /* Add styles for the hamburger menu */
  .hamburger-menu {
    position: relative;
    text-align: left;
  }
  
  .menu-icon {
    cursor: pointer;
    display: inline-block; /* Display the icon inline with the menu text */
    vertical-align: middle; 
  }
  
  .bar {
    width: 25px;
    height: 3px;
    background-color: #333;
    margin: 5px 0;
  }
  
  .menu-list {
    width: 250px;
    height: 100vh;
    position: absolute;
    top: 0px;
    left: 20px;
    /* list-style: none; */
    padding: 0;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 3px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
  }

  ul {
    list-style-type: none;
    padding: 0;
  }
  
  li {
    padding: 15px;
    cursor: pointer;
    transition: 0.3s;
  }
  
  li:hover {
    background-color: #0056b3;
  }
  
  .menu-list.active {
    opacity: 1;
    visibility: visible;
  }
  
  .menu-list li {
    padding: 10px;
    cursor: pointer;
  }
  
  .menu-list li:hover {
    background-color: #eee;
  }
  </style>
  