<template>
    <HamburgerMenu />
    <div class="two-column-layout">
      <!-- Form Column -->
      <div class="form-column">
        <span v-if="!isEditing">
          
            <LegsForm @add-leg="addLeg" :isLoading="isLoading" />
          
        </span>
          
        <span v-else>
          
            <LegEditForm @edit-leg="editItem" @cancel-edit="this.isEditing = false" :isLoading="isLoading" :leg="this.legEditing" />
            
          
        </span>
      </div>
      
  
      <!-- List Column -->
      <div class="list-column">
        <LegCard @delete-leg="deleteItem" @edit-leg="selectEditItem" v-for="card in legsList" :key="card.id" :cardData="card" />
        <span v-if="!isLoading">
        </span>
        
        <span v-else>
          <i class="fas fa-spinner fa-spin"></i> Loading...
        </span>
      </div>
      
    </div>
  </template>
  
  
  <script>
  import LegsForm from '../components/legs/LegsForm.vue';
  import LegEditForm from '../components/legs/LegEditForm.vue';
  import LegCard from '../components/legs/LegCard.vue';
  import HamburgerMenu from '../components/HamburgerMenu.vue';
  
  
//   import axios from 'axios';
  import api from '../interceptors/axiosInterceptos'; 
  import Swal from 'sweetalert2';
  export default {
    
    
    components: {
      LegsForm,
      LegCard,
      HamburgerMenu,
      LegEditForm
    },
    data() {
      return {
        // legsList: [{"server": "blackbullus.primexm.com", "port": 21177, "trade": true}], // Populate this array with your card data
        legsList: [],
        isLoading: false,
        isEditing: false,
        legEditing: null
      };
    },
    methods: {
          async addLeg(leg) {
            try {
              this.isLoading = true;
              const token = localStorage.getItem('token');
              const headers = { 
                "authorization": `Bearer ${token}`,
              };
  
              const response = await api.post(`${process.env.API_URL}/legs/create`,  leg, { headers });
              if(response.status == 200){
                
                // Inside a component method or event handler
                Swal.fire({
                  icon: 'success',
                  title: 'Success!',
                  text: 'Your request was successful.',
                });
                this.isLoading = false;
  
                this.legsList = [...this.legsList, response.data]
              }
  
            } catch (error) {
              // Handle authentication error (e.g., display error message)
              this.isLoading = false;
              Swal.fire({
                icon: 'error',
                title: 'Error!',
                text: error,
              })
              this.isLoading = false;
            }
          },
          async fetchData() {
            if (this.isLoading) {
              return; // Prevent multiple submissions while loading
            }
            try {
              this.isLoading = true;
              const token = localStorage.getItem('token');
              const headers = { 
                "authorization": `Bearer ${token}`,
              };
              const response = await api.get(`${process.env.API_URL}/legs/`, { headers });
              this.isLoading = false;
              return response.data; // Assuming the data is an array
            } catch (error) {
              console.error('Error fetching data:', error);
              this.isLoading = false;
            }
          },
          selectEditItem(leg) {
            this.isEditing = true;
            this.legEditing = leg;
  
          },
          async editItem(leg) {
            try {
              this.isLoading = true;
              const token = localStorage.getItem('token');
              const headers = { 
                "authorization": `Bearer ${token}`,
              };
  
              const response = await api.put(`${process.env.API_URL}/legs/${leg.id}`,  leg, { headers });
              if(response.status == 200){
                
                // Inside a component method or event handler
                Swal.fire({
                  icon: 'success',
                  title: 'Success!',
                  text: 'Your request was successful.',
                });
                this.isLoading = false;
                const index = this.legsList.findIndex((card) => card.id === leg.id);

                if (index !== -1) {
                  // Remove the item from the array
                  this.legsList.splice(index, 1);

                  // Insert the updated item at the original index
                  this.legsList.splice(index, 0, leg);
                }
                this.isEditing = false;
              }
  
              
            } catch (error) {
              // Handle authentication error (e.g., display error message)
              this.isLoading = false;
              Swal.fire({
                icon: 'error',
                title: 'Error!',
                text: error,
              })
              this.isLoading = false;
            }
          },
          async deleteItem(id) {
            Swal.fire({
              title: 'Confirm Action',
              text: 'Are you sure you want to continue?',
              icon: 'warning',
              showCancelButton: true,
              confirmButtonText: 'Yes, continue',
              cancelButtonText: 'No, cancel',
            }).then(async (result) => {
              if (result.isConfirmed) {
                // The user clicked "Yes, continue"
                // Perform the action you want to confirm here
                // For example, call a function or make an API request
                // ...
                try {
                  const token = localStorage.getItem('token');
                  const headers = { 
                    "authorization": `Bearer ${token}`,
                  };
                  const response = await api.delete(`${process.env.API_URL}/legs/${id}`, { headers });
                  if(response.status == 200) {
                    this.legsList = this.legsList.filter((card) => card.id !== id)
                  }
                  Swal.fire({
                    icon: 'success',
                    title: 'Success!',
                    text: 'Your request was successful.',
                  });
                  
                } catch (error) {
                  console.error('Error deleting connection:', error);
                  Swal.fire({
                    icon: 'error',
                    title: 'Error!',
                    text: error,
                  })
                }
              }
            });
              
            }
          },
      
      async created() {
        this.legsList = await this.fetchData(); // Call the fetchData method when the component is created
      },
  };
  </script>
  
  <style scoped>
  .two-column-layout {
    display: flex; /* Use flexbox to create the two-column layout */
    flex-wrap: wrap; /* Allow columns to wrap on smaller screens */
    background-image: linear-gradient(to bottom, #f0f0f0, #e0e0e0); /* Background gradient */
    min-height: 100vh; /* Ensure the background covers the entire viewport height */
  

  }
  
  .form-column {
    flex: 1; /* Use flex to make the form column flexible */
    padding: 20px; /* Add padding for spacing */
  }
  
  .list-column {
    flex: 1; /* Use flex to make the list column flexible */
    padding: 20px; /* Add padding for spacing */
  }
  
  /* Add media queries to adjust layout for smaller screens */
  @media (max-width: 768px) {
    .two-column-layout {
      flex-direction: column; /* Switch to one column on smaller screens */
    }
  }
  
  button {
    display: block;
    width: 100%;
    max-width: 400px; /* Adjust the width as needed */
    margin: 0 auto;
    padding: 10px;
    background-color: #007BFF;
    color: #fff;
    border: none;
    border-radius: 3px;
    cursor: pointer;
  }
  </style>
  