<template>
  <HamburgerMenu />
  <span v-if="isDetail">
    <InstanceDetail 
    @return-instance="returnInstance" :instance="this.instanceDetail" @start-instance:instance="startItem"/>
  </span>
  <span v-else>
    <div class="two-column-layout">
      <!-- Form Column -->        
        <div class="form-column">
            <span v-if="!isEditing && !isDetail">
              <InstancesForm @add-instance="addInstance" :isLoading="isLoading" :connectionsList="connectionsList" :legsList="legsList"/>
            </span>              
            <span v-else>
              <EditInstancesForm @edit-instance="editItem" @cancel-edit="this.isEditing = false" :isLoading="isLoading" :instance="this.instanceEditing" :connectionsList="connectionsList" :legsList="legsList" />
            </span>
          </div>
          <!-- List Column -->
          <div class="list-column">
              <span v-if="!isDetail">
                <InstanceCard :isLoading="isLoading" @delete-instance="deleteItem" @edit-instance="selectEditItem" @detail-instance="selectDetailItem" @test-instance="testItem" @start-instance="startItem" v-for="card in instancesList" :key="card.id" :cardData="card" />
              <span v-if="!isLoading">
              </span>
              <span v-else>
                <i class="fas fa-spinner fa-spin"></i> Loading...
              </span>
            </span>
          </div>
    </div>
  </span>
</template>
  
<script>
  import InstancesForm from '../components/instances/InstancesForm.vue';
  import EditInstancesForm from '../components/instances/InstancesEditForm.vue';
  import InstanceCard from '../components/instances/InstanceCard.vue';
  import HamburgerMenu from '../components/HamburgerMenu.vue';
  import InstanceDetail from '../components/instances/InstanceDetail.vue';
  
  
//   import axios from 'axios';
  import api from '../interceptors/axiosInterceptos'; 
  import Swal from 'sweetalert2';
  export default {
    
    
    components: {
      InstancesForm,
      InstanceCard,
      HamburgerMenu,
      InstanceDetail,
      EditInstancesForm
    },
    data() {
      return {
        instancesList: [],
        legsList: [],
        connectionsList: [],
        isLoading: false,
        isEditing: false,
        isDetail: false,
        instanceEditing: null,
      };
    },
    methods: {
          async addInstance(leg) {
            try {
              this.isLoading = true;
              const token = localStorage.getItem('token');
              const headers = { 
                "authorization": `Bearer ${token}`,
              };
  
              const response = await api.post(`${process.env.API_URL}/instances/create`,  leg, { headers });
              if(response.status == 200){
                
                // Inside a component method or event handler
                Swal.fire({
                  icon: 'success',
                  title: 'Success!',
                  text: 'Your request was successful.',
                });
                this.isLoading = false;
  
                this.instancesList = [...this.instancesList, response.data]
              }
  
            } catch (error) {
              // Handle authentication error (e.g., display error message)
              this.isLoading = false;
              Swal.fire({
                icon: 'error',
                title: 'Error!',
                text: error,
              })
              this.isLoading = false;
            }
          },
          async fetchData() {
            if (this.isLoading) {
              return; // Prevent multiple submissions while loading
            }
            try {
              this.isLoading = true;
              const token = localStorage.getItem('token');
              const headers = { 
                "authorization": `Bearer ${token}`,
              };
              const response = await api.get(`${process.env.API_URL}/instances/`, { headers });
              this.isLoading = false;
              return response.data; // Assuming the data is an array
            } catch (error) {
              console.error('Error fetching data:', error);
              this.isLoading = false;
            }
          },
          async fetchLegs() {
            // if (this.isLoading) {
            //   return; // Prevent multiple submissions while loading
            // }
            try {
            //   this.isLoading = true;
              const token = localStorage.getItem('token');
              const headers = { 
                "authorization": `Bearer ${token}`,
              };
              const response = await api.get(`${process.env.API_URL}/legs/`, { headers });
            //   this.isLoading = false;
              return response.data; // Assuming the data is an array
            } catch (error) {
              console.error('Error fetching data:', error);
            //   this.isLoading = false;
            }
          },
          async fetchConnections() {
            // if (this.isLoading) {
            //   return; // Prevent multiple submissions while loading
            // }
            try {
              //this.isLoading = true;
              const token = localStorage.getItem('token');
              const headers = { 
                "authorization": `Bearer ${token}`,
              };
              const response = await api.get(`${process.env.API_URL}/fixapisessions/`, { headers });
            //   this.isLoading = false;
              return response.data; // Assuming the data is an array
            } catch (error) {
              console.error('Error fetching data:', error);
            //   this.isLoading = false;
            }
          },
          returnInstance(){
            this.isDetail = false;
          },
          selectEditItem(instance) {
            this.isEditing = true;
            this.instanceEditing = instance;
  
          },
          selectDetailItem(instance) {
            this.isDetail = true;
            console.log(instance);
            this.instanceDetail = instance;
          },
          async editItem(instance) {
            try {
              this.isLoading = true;
              const token = localStorage.getItem('token');
              const headers = { 
                "authorization": `Bearer ${token}`,
              };
  
              const response = await api.put(`${process.env.API_URL}/instances/${instance.id}`,  instance, { headers });
              if(response.status == 200){
                
                // Inside a component method or event handler
                Swal.fire({
                  icon: 'success',
                  title: 'Success!',
                  text: 'Your request was successful.',
                });
                this.isLoading = false;
                const index = this.instancesList.findIndex((card) => card.id === instance.id);

                if (index !== -1) {
                  // Remove the item from the array
                  this.instancesList.splice(index, 1);

                  // Insert the updated item at the original index
                  this.instancesList.splice(index, 0, instance);
                }
                this.isEditing = false;
              }
  
              
            } catch (error) {
              // Handle authentication error (e.g., display error message)
              this.isLoading = false;
              Swal.fire({
                icon: 'error',
                title: 'Error!',
                text: error,
              })
              this.isLoading = false;
            }
          },
          async deleteItem(id) {
            Swal.fire({
              title: 'Confirm Action',
              text: 'Are you sure you want to continue?',
              icon: 'warning',
              showCancelButton: true,
              confirmButtonText: 'Yes, continue',
              cancelButtonText: 'No, cancel',
            }).then(async (result) => {
              if (result.isConfirmed) {
                // The user clicked "Yes, continue"
                // Perform the action you want to confirm here
                // For example, call a function or make an API request
                // ...
                try {
                  const token = localStorage.getItem('token');
                  const headers = { 
                    "authorization": `Bearer ${token}`,
                  };
                  const response = await api.delete(`${process.env.API_URL}/instances/${id}`, { headers });
                  if(response.status == 200) {
                    this.instancesList = this.instancesList.filter((card) => card.id !== id)
                  }
                  Swal.fire({
                    icon: 'success',
                    title: 'Success!',
                    text: 'Your request was successful.',
                  });
                  
                } catch (error) {
                  console.error('Error deleting connection:', error);
                  Swal.fire({
                    icon: 'error',
                    title: 'Error!',
                    text: error,
                  })
                }
              }
            });
              
            },
            async testItem(id) {
              try {
            
                this.isLoading = true;
                const token = localStorage.getItem('token');
                const headers = { 
                  "authorization": `Bearer ${token}`,
                };
                const response = await api.post(`${process.env.API_URL}/instances/test/${id}`,'', { headers });
                if(response.status == 200) {
                  Swal.fire({
                  icon: 'success',
                  title: 'Success!',
                  text: 'Your instance its correctly configured you can know start it.',
                });
                }
                
                this.isLoading = false;
              } catch (error) {
                this.isLoading = false;
                const e = error.response.data.message.code;
                console.error('Error testing connection:', error);
                Swal.fire({
                  icon: 'error',
                  title: 'Error!',
                  text: e != undefined ? `${e} ${error.response.data.message.address}:${error.response.data.message.port}` : "Error testing connection",
                })
              }
            },
            async startItem(instance) {
               // Toggle the state
              if (instance.isRunning) {
                try {
            
                  this.isLoading = true;
                  const token = localStorage.getItem('token');
                  const headers = { 
                    "authorization": `Bearer ${token}`,
                  };
                  console.log(`${process.env.API_URL}/instances/stop/${instance.id}`)
                  const response = await api.post(`${process.env.API_URL}/instances/stop/${instance.id}`,'', { headers });
                  if(response.status == 200) {
                    Swal.fire({
                      icon: 'success',
                      title: 'Success!',
                      text: 'Your instance has stoped correctly.',
                    });
                    instance.isRunning = !instance.isRunning;
                  }
                  else {
                    Swal.fire({
                      icon: 'error',
                      title: 'Error!',
                      text: "Error stoping connection",
                    })
                  }
                  
                  this.isLoading = false;
                } catch (error) {
                  this.isLoading = false;
                  const e = error.response.data.message.code;
                  console.error('Error stoping connection:', error);
                  Swal.fire({
                    icon: 'error',
                    title: 'Error!',
                    text: e != undefined ? `${e} ${error.response.data.message.address}:${error.response.data.message.port}` : "Error stoping connection",
                  })
                }
              } else {
                try {
            
                  this.isLoading = true;
                  const token = localStorage.getItem('token');
                  const headers = { 
                    "authorization": `Bearer ${token}`,
                  };
                  const response = await api.post(`${process.env.API_URL}/instances/start/${instance.id}`,'', { headers });
                  if(response.status == 200) {
                    Swal.fire({
                      icon: 'success',
                      title: 'Success!',
                      text: 'Your instance has started correctly.',
                    });
                    instance.isRunning = !instance.isRunning;
                  } else {
                    Swal.fire({
                      icon: 'error',
                      title: 'Error!',
                      text: "Error starting connection",
                    })
                  }
                  
                  this.isLoading = false;
                } catch (error) {
                  console.log(error.response?.data?.message ?? error.message)
                  this.isLoading = false;
                  const e = error.response.data.message.code;
                  console.error('Error starting connection:', error);
                  Swal.fire({
                    icon: 'error',
                    title: 'Error!',
                    text: e != undefined ? `${e} ${error.response.data.message.address}:${error.response.data.message.port}` : "Error starting connection",
                  })
                }
              }
              
            }
          },
      
      async created() {
        this.instancesList = await this.fetchData(); // Call the fetchData method when the component is created
        this.legsList = await this.fetchLegs();
        this.connectionsList = await this.fetchConnections();
      },
  };
  </script>
  
  <style scoped>
  .two-column-layout {
    display: flex; /* Use flexbox to create the two-column layout */
    flex-wrap: wrap; /* Allow columns to wrap on smaller screens */
    background-image: linear-gradient(to bottom, #f0f0f0, #e0e0e0); /* Background gradient */
    min-height: 100vh; /* Ensure the background covers the entire viewport height */
  }
  
  .form-column {
    flex: 1; /* Use flex to make the form column flexible */
    padding: 20px; /* Add padding for spacing */
  }
  
  .list-column {
    flex: 1; /* Use flex to make the list column flexible */
    padding: 20px; /* Add padding for spacing */
  }
  
  /* Add media queries to adjust layout for smaller screens */
  @media (max-width: 768px) {
    .two-column-layout {
      flex-direction: column; /* Switch to one column on smaller screens */
    }
  }
  
  button {
    display: block;
    width: 100%;
    max-width: 400px; /* Adjust the width as needed */
    margin: 0 auto;
    padding: 10px;
    background-color: #007BFF;
    color: #fff;
    border: none;
    border-radius: 3px;
    cursor: pointer;
  }
  </style>
  